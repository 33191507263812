<template>
  <div>
    <div class="maintitle">用户列表</div>
    <el-divider></el-divider>
    <el-table :data="list" border>
      <el-table-column align="center" prop="nickname" label="昵称"></el-table-column>
      <el-table-column align="center" prop="tel" label="联系电话"></el-table-column>
      <el-table-column align="center" prop="regtime" label="注册时间"></el-table-column>
      <el-table-column align="center" prop="recommon" label="推荐人"></el-table-column>
      <el-table-column align="center" prop="status_text" label="状态"></el-table-column>
      <el-table-column align="center" label="推广权限">
        <template slot-scope="scope">
          <span v-if="scope.row.recommon_auth==1">可以推广</span>
        </template>
      </el-table-column>
      <el-table-column align="center" type="index" fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="changestatus(scope)">{{scope.row.status==1?"禁用":"启用"}}</el-button>
            <el-button size="small" @click="$router.push('/member/transaction/'+scope.row.openid)">查看流水</el-button>
            <el-button size="small" @click="setRecommon(scope)"><span v-if="scope.row.recommon_auth == 1">禁止推广</span><span v-else>允许推广</span></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //业务人员列表

      page: 1, //当前页数
      // pagesize: this.pagesize,//当前每页条数
      // pagesizes: this.pagesizes,//每页条数列表
      total: 0 //信息总条数
    };
  },
  computed: {},
  methods: {
    getlist: function() {
      let that = this;
      this.$axios({
        url: this.HOST + "/jiameng/member/getlist",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.total = res.data.list.total;
          this.list = res.data.list.data;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    changestatus(scope) {
      let status = scope.row.status == 1 ? 2 : 1;
      let id = scope.row.openid;
      let status_text = status == 2 ? "已禁用" : "正常";
      let statustext = status == 2 ? "禁用" : "启用";
      let that = this;
      let level1 = false;
      let str = "确定将" + scope.row.nickname + statustext + "吗？";
      this.$confirm(str, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          that
            .$axios({
              url: that.HOST + "/jiameng/member/changstatus",
              method: "post",
              data: { id: id, status: status }
            })
            .then(function(res) {
              if (res.data.status == 1) {
                scope.row.status_text = status_text;
                scope.row.status = status;
              }
            }),
            error => {
              that.$alert("操作失败", "温馨提示");
            };
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    setRecommon(scope){
      let recommon_auth = scope.row.recommon_auth == 1?0:1;
      this.$axios({
        url:this.HOST + '/jiameng/member/recommonauth',
        method:'post',
        data:{
          openid:scope.row.openid,
          recommon_auth:recommon_auth
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'info',
            message:"操作成功"
          });
          scope.row.recommon_auth = recommon_auth;
        }
      });
    }
  },
  created: function() {
    this.getlist();
  }
};
</script>

<style>
</style>